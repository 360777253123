<script setup>
import MainBackground from '@/assets/images/004.jpg'
import ImageBackgroundVue from '@/components/ui/ImageBackground.vue'

import DaraeLabInfoVue from '@/components/ui/DaraeLabInfo.vue'
import SearchBarVue from '@/components/ui/SearchBar.vue'

import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { regRemoval } from '@/util/regex'

const router = useRouter()
const store = useStore()
const src = MainBackground

const goSearchResult = (value, option) => {
  const replacedValue = value.replace(regRemoval, '')
  router.push(`/integratedSearch/integratedSearchResult?q=${replacedValue}&opt=${option}`)
}

const conditions = store.state.searchStore.conditions
</script>

<template>
  <div>
    <ImageBackgroundVue class="wf__full-width" :src="src">
      <DaraeLabInfoVue class="mb-4" />
    </ImageBackgroundVue>

    <section class="wf__search">
      <SearchBarVue @search="goSearchResult" :searchConditions="conditions" />
    </section>

    <router-view></router-view>
  </div>
</template>

<style scoped>
.wf__search {
  font-size: var(--wf-text-16);
  margin-top: 3em;
  margin-bottom: 4em;
  text-align: center;
}
</style>
